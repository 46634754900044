@keyframes slideIn {
  from {
    max-height: 0;
  }
  to {
    max-height: 85px;
  }
}

@keyframes slideOut {
  from {
    max-height: 85px;
  }
  to {
    max-height: 0;
  }
}
